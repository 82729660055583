import './App.css';
import {MyDropzone} from './DropZone/dropzone';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <MyDropzone />
            </header>
        </div>
    );
}

export default App;
